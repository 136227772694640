<!-- 整体趋势 -->
<template>
  <section class="cont overallTrend">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <div class="card-box">
        <div class="card-box-item">
          <p class="item-tit">7日累计新增用户数</p>
          <p class="item-tit m-top-10 m-bottom-20">{{ hebdomad }}~{{ today }}</p>
          <p class="item-text">{{ newUserCount7 }}人</p>
        </div>
        <div class="card-box-item">
          <p class="item-tit">30日累积新增用户数</p>
          <p class="item-tit m-top-10 m-bottom-20">{{ month }}~{{ today }}</p>
          <p class="item-text">{{ newUserCount30 }}人</p>
        </div>
        <div class="card-box-item">
          <p class="item-tit">7日小程序累计活跃用户数</p>
          <p class="item-tit m-top-10 m-bottom-20">{{ hebdomad }}~{{ today }}</p>
          <p class="item-text">{{ activeUserCount7 }}人</p>
        </div>
        <div class="card-box-item">
          <p class="item-tit">30日小程序累积活跃用户数</p>
          <p class="item-tit m-top-10 m-bottom-20">{{ month }}~{{ today }}</p>
          <p class="item-text">{{ activeUserCount30 }}人</p>
        </div>
      </div>
    </el-row>
    <div class="m-top-20 chart-box">
      <el-col class="content-box chart-item">
        <span class="m-bottom-10">30日</span>
        <span class="m-left-5 m-right-5">|</span>
        <span>新增用户趋势</span>
        <p>{{ month }}~{{ today }}</p>
        <CurveChart class="charBox" name="新增用户" :xAxis="addUser_x" :series="addUser_y" chartId="addUser" ref="addUser"></CurveChart>
      </el-col>
      <el-col class="content-box chart-item">
        <span class="m-bottom-10">30日</span>
        <span class="m-left-5 m-right-5">|</span>
        <span>活跃用户趋势</span>
        <p>{{ month }}~{{ today }}</p>
        <CurveChart class="charBox" name="活跃用户" :xAxis="activeUser_x" :series="activeUser_y" chartId="activeUser" ref="activeUser"></CurveChart>
      </el-col>
    </div>
  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import { dateFactory } from '@/common/js/common'
  import { getNewUsersDay, getUserCountDay, getNewUserCountDay, getActiveUserCount } from '@/api/business/userStatistics/realTimeStatistics'
  export default {
    name: 'overallTrend',
    data() {
      return {
        crumbs: new Map([['业务中台'], ['实时用户统计'], ['整体趋势']]),
        today: dateFactory.getDistanceToday(0, false),

        newUserCount7: 0,     // 7天 新增用户数量
        newUserCount30: 0,    // 30天 新增用户数量
        activeUserCount7: 0,  // 7天 用户活跃数量
        activeUserCount30: 0, // 30天 用户活跃数量

        addUser_x: [],        // 新增用户 X轴
        addUser_y: [],        // 新增用户 Y轴

        activeUser_x: [],     // 活跃用户 X轴
        activeUser_y: [],     // 活跃用户 Y轴
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
      hebdomad() {
        return dateFactory.getDistanceToday(-6, false)
      },
      month() {
        return dateFactory.getDistanceToday(-29, false)
      },
    },
    mounted() {
      this.getData()
    },
    methods: {
      getData() {
        const data = {
          dateStr: this.today,
          companyId: this.hotelInfo.storeId, // 集团ID
        }
        // 30天新增用户
        getNewUsersDay(data).then(({ success, records }) => {
          if (success) {
            for (let i = 0; i < records.length; i++) {
              this.addUser_x[29 - i] = records[i].day.split('-')[2]
              this.addUser_y[29 - i] = records[i].count
            }
            this.$refs.addUser.drawChart()
          }
        })
        // 30天活跃人数(小程序)
        getUserCountDay({ ...data, client: 'WECHAT_APPLET' }).then(({ success, records }) => {
          if (success) {
            for (let i = 0; i < records.length; i++) {
              this.activeUser_x[29 - i] = records[i].day.split('-')[2]
              this.activeUser_y[29 - i] = records[i].userCount
            }
            this.$refs.activeUser.drawChart()
          }
        })
        getNewUserCountDay({ hotelId: this.hotelInfo.id }).then(({ success, records }) => {
          if (success) {
            this.newUserCount7 = records.count7
            this.newUserCount30 = records.count30
          }
        })
        getActiveUserCount({ hotelId: this.hotelInfo.id }).then(({ success, records }) => {
          if (success) {
            this.activeUserCount7 = records.count7
            this.activeUserCount30 = records.count30
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .overallTrend {
    .card-box {
      $gradient-blue: linear-gradient(90deg, #006fff, #189aff);
      $gradient-green: linear-gradient(90deg, rgb(78, 211, 181), rgb(41, 231, 188));
      $gradient-org: linear-gradient(90deg, rgb(245, 108, 108), rgb(244, 127, 64));
      $gradient-yellow: linear-gradient(90deg, rgb(255, 161, 50), rgb(255, 209, 50));
      $gradient-red: linear-gradient(90deg, rgb(163, 50, 255), rgb(151, 99, 233));
      $list-bg: $gradient-blue, $gradient-green, $gradient-org, $gradient-yellow, $gradient-red;

      display: flex;
      justify-content: space-between;
      .card-box-item {
        width: 22%;
        padding: 30px 40px;
        background-repeat: no-repeat;
        text-align: left;
        background-position: 0 0;
        background-size: 100% 100%;
        box-sizing: border-box;
        color: #ffffff;
        border-radius: 6px;
        @each $c in $list-bg {
          $i: index($list-bg, $c);
          &:nth-child(#{$i}) {
            background: nth($list-bg, $i);
          }
        }
        .item-tit {
          font-size: 18px;
          font-weight: 400;
        }
        .item-text {
          font-size: 36px;
          font-weight: 700;
        }
      }
    }
    .chart-box {
      display: flex;
      font-size: 18px;
      justify-content: space-between;
      .chart-item {
        width: calc(50% - 10px);
        .charBox {
          width: 100%;
          height: 300px;
        }
      }
    }
  }
</style>
